import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { baseUrl_V2 } from "src/utils/config";
import { appendToken } from "../utils";
import {
  FactSheetDetails,
  NavEventItem,
  SmartfolioDetails,
  SmartfolioList,
  SmartfoliosParams,
  Structure,
} from "./types";

export const TAG_INVESTMENT_DETAILS = "INVESTMENT_DETAILS";
export const TAG_EXIT_PUBLIC_INVESTMENT = "EXIT_PUBLIC_INVESTMENT";
export const TAG_CANCEL_PUBLIC_INVESTMENT = "CANCEL_PUBLIC_INVESTMENT";
export const TAG_INVESTMENT_TRANSACTION_HISTORY =
  "INVESTMENT_TRANSACTION_HISTORY";
export const TAG_MARKET_INVESTMENTS = "MARKET_INVESTMENTS";

export const v2Api = createApi({
  reducerPath: "v2",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl_V2,
    prepareHeaders: appendToken,
  }),
  tagTypes: [
    TAG_INVESTMENT_DETAILS,
    TAG_EXIT_PUBLIC_INVESTMENT,
    TAG_INVESTMENT_TRANSACTION_HISTORY,
    TAG_MARKET_INVESTMENTS,
    TAG_CANCEL_PUBLIC_INVESTMENT,
  ],
  endpoints: (builder) => ({
    getRecommendedSmartfolios: builder.query<SmartfolioList, SmartfoliosParams>(
      {
        query: (options) => ({
          url: "/dashboard/recommendations/smartfolios/",
          method: "GET",
          params: { ...options },
        }),
      },
    ),
    getSmartfolioDetailsById: builder.query<SmartfolioDetails, string>({
      query: (id) => ({
        url: `/dashboard/global-info/smartfolios/${id}`,
        method: "GET",
      }),
    }),
    getStructure: builder.query<Structure, void>({
      query: () => ({
        url: `/dashboard/smartfolios/structure`,
        method: "GET",
      }),
      keepUnusedDataFor: Infinity,
    }),
    getFactsheetBySmartfolioId: builder.query<FactSheetDetails, string>({
      query: (smartfolioId) => ({
        url: `/dashboard/global-info/factsheet/?smartfolio_id=${smartfolioId}&file_format=json`,
        method: "GET",
      }),
    }),
    getNavBySmartfolioId: builder.query<any, string>({
      query: (smartfolioId) => ({
        url: `/dashboard/smartfolios/${smartfolioId}/nav/`,
        method: "GET",
      }),
    }),
    getPerofrmancesById: builder.query<NavEventItem[], string>({
      query: (investmentId) => ({
        url: `/dashboard/investments/${investmentId}/performances`,
        method: "GET",
      }),
    }),

    topUpOtpInitializer: builder.mutation<any, any>({
      query: ({ investmentId }) => ({
        url: `/dashboard/investments/open/${investmentId}/top-up?resend=true`,
        method: "POST",
      }),
    }),

    authenticateTopUpOtp: builder.mutation<any, any>({
      query: ({ investmentId, amount, otp }) => ({
        url: `/dashboard/investments/open/${investmentId}/top-up?otp=${otp}`,
        method: "POST",
        body: {
          amount: amount,
          new_stop_loss: null,
          new_take_profit: null,
          note: "Top Up",
        },
      }),
      invalidatesTags: [
        TAG_INVESTMENT_DETAILS,
        TAG_INVESTMENT_TRANSACTION_HISTORY,
        TAG_MARKET_INVESTMENTS,
      ],
    }),
    getInvestmentDetailsInfo: builder.query<any, any>({
      query: (id) => ({
        url: `/dashboard/investments/${id}`,
      }),
      providesTags: [
        TAG_INVESTMENT_DETAILS,
        TAG_EXIT_PUBLIC_INVESTMENT,
        TAG_CANCEL_PUBLIC_INVESTMENT,
      ],
    }),

    exitPublicInvestment: builder.mutation<any, any>({
      query: ({ id, otp }) => ({
        url: `/dashboard/investments/${id}/exit?otp=${otp}`,
        method: "POST",
      }),
      invalidatesTags: [TAG_EXIT_PUBLIC_INVESTMENT, TAG_MARKET_INVESTMENTS],
    }),

    cancelPublicInvestment: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/dashboard/investments/${id}/cancel?resend=true`,
        method: "PUT",
      }),
      invalidatesTags: [TAG_CANCEL_PUBLIC_INVESTMENT],
    }),

    cancelPublicInvestmentOTP: builder.mutation<any, any>({
      query: ({ id, otp }) => ({
        url: `/dashboard/investments/${id}/cancel?otp=${otp}`,
        method: "PUT",
      }),
      invalidatesTags: [TAG_CANCEL_PUBLIC_INVESTMENT],
    }),

    getInvestmentTransactionHistory: builder.query<any, any>({
      query: (id) => ({
        url: `/dashboard/investments/events`,
        params: { investment_id: id, page_number: 1, page_size: 100 },
      }),
      providesTags: [TAG_INVESTMENT_TRANSACTION_HISTORY],
    }),

    getMarketInvestments: builder.query<any, any>({
      query: ({ type, page_number = 1, page_size = 100 }) => ({
        url: `/dashboard/investments`,
        params: {
          investment_type: type,
          page_number: page_number,
          page_size: page_size,
        },
      }),
      providesTags: [TAG_MARKET_INVESTMENTS],
    }),
    getPrivateSmartFolioVideo: builder.query<any, any>({
      query: ({ id }) => ({
        url: `/public/smartfolios/${id}/factsheet?file_type=video`
      }),
      // providesTags: [TAG_MARKET_INVESTMENTS],
    }),
    getPrivateSmartFolioDocument: builder.query<any, any>({
      query: ({id, fileFormat, fileType}) => ({
        url: `/public/smartfolios/${id}/factsheet?file_format=${fileFormat}&file_type=${fileType}`
      }),
      // providesTags: [TAG_MARKET_INVESTMENTS],
    }),
  }),
});

export const {
  useGetRecommendedSmartfoliosQuery,
  useGetSmartfolioDetailsByIdQuery,
  useGetFactsheetBySmartfolioIdQuery,
  useGetNavBySmartfolioIdQuery,
  useGetPerofrmancesByIdQuery,
  useTopUpOtpInitializerMutation,
  useAuthenticateTopUpOtpMutation,
  useGetStructureQuery,
  useGetInvestmentDetailsInfoQuery,
  useExitPublicInvestmentMutation,
  useGetInvestmentTransactionHistoryQuery,
  useGetMarketInvestmentsQuery,
  useCancelPublicInvestmentMutation,
  useCancelPublicInvestmentOTPMutation,
  useGetPrivateSmartFolioVideoQuery,
  useGetPrivateSmartFolioDocumentQuery
} = v2Api;
