import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContributionData } from "src/store/Goal-Based/goal";
import moment from "moment";
import { UpdateFieldPayload } from "./goal";

const initialState: ContributionData = {
  period: 12,
  start_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  end_date: moment().add(5, "month").format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  contribution_type: 1,
  initial_amount: "0",
  contribution_amount: "500",
  objective: null,
};

const ContributionAmountSlice = createSlice({
  name: "contributionAmount",
  initialState,
  reducers: {
    updateContributionField: (
      state: any,
      action: PayloadAction<UpdateFieldPayload<ContributionData>>,
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { updateContributionField } = ContributionAmountSlice.actions;
export default ContributionAmountSlice.reducer;
