import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  currentState: string;
}

const initialState: MenuState = {
  currentState: 'GoalAmount',
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuState: (state, action: PayloadAction<MenuState>) => {
      state.currentState = action.payload.currentState;
    },
  },
});

export const { setMenuState } = menuSlice.actions;
export default menuSlice.reducer;
