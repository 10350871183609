import { message } from "antd";
import { useMutation } from "react-query";
import axiosInstance from "src/utils/axiosInstance";
import { SellI } from "../utils/interfaces";

const ADD_SELL_STOCK_ORDER_ENDPOINT = '/dashboard/direct-orders/sell'

const postSellTicker = async (payload: SellI) => {
    const response = await axiosInstance.post(ADD_SELL_STOCK_ORDER_ENDPOINT, payload, { baseURL: 'v2' });
    return response.data;
};

export const useSellStockOrder = () => {
    return useMutation(postSellTicker, {
        onSuccess: () => {
            message.success("Data saved successfully!")
        },
        onError: (error) => {
            message.error("Something went wrong!")
        },
    });
}