import styled from "styled-components/macro";
import { Radio as AntRadio } from "antd";

import { typography } from "src/utils/styledComponents/typography";
import { theme } from "src/theme/theme";

const sizes = {
  mobileS: "550px",
};

export const devices = {
  mobile: `(max-width: ${sizes.mobileS})`,
  other: `(min-width: ${sizes.mobileS})`,
};

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typography.CustomText("1em")}
  align-items: center;
`;

export const Wrapper = styled.div`
  /* @media ${devices.other} {
    width: 85%;
  } */
  width: 85%;
  @media ${devices.mobile} {
    width: 100%;
  }
  background-color: #f7f9fb;
  border: 1px solid #e8f1ff;
  border-radius: 12px;
  margin-top: 2em;
  @media ${devices.other} {
    padding: 2.25em 4em;
  }
  @media ${devices.mobile} {
    padding: 2.25em 0.2em;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  text-align: center;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  padding-left: 0px;
`;

export const ListItem = styled.li`
  @media ${devices.other} {
    display: flex;
    justify-content: space-between;
  }
  text-align: center;
  text-transform: capitalize;
  list-style: none;
`;

export const Name = styled.p<{ color?: string }>`
  @media ${devices.mobile} {
    margin: 0px;
    text-align: center;
  }
  text-align: left;
  opacity: 0.5;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const RightValue = styled.p<{ color?: string }>`
  width: 50%;
  text-align: right;
  @media ${devices.mobile} {
    width: 100%;
    text-align: center;
  }
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 19px;
  color: ${({ color }) => (color ? color : "#000")};
`;

export const Small = styled.span`
  font-size: 1em;
  opacity: 0.5;
  text-align: center;
  width: 80%;
`;

export const SectionText = styled.span`
  color: ${({ color }) => (color ? color : "#000")};
  font-weight: 700;
  line-height: 19.5px;
  font-size: 16px;
  font-family: proxima-Nova;
  whitespace: no-wrap;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const RadioContainer = styled.div`
  // border: 1px solid red;
  gap: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RadioText = styled.span`
  font-weight: 400;
  line-height: 19.5px;
  font-size: 16px;
  font-family: proxima-Nova;
`;

export const Radio = styled(AntRadio)`
  float: left;
  margin: 8px;
  .ant-radio-checked .ant-radio-inner {
    border-color: ${theme.colors.primary[500]};
  }
  .ant-radio-inner:after {
    background-color: ${theme.colors.primary[500]} !important;
  }
`;
