import React from "react";
import { useFormik, yupToFormErrors } from "formik";
import { string, object } from "yup";
import { OTPModal, SuccessModal } from "../.";
import { cancelOrders, validateCancelOrders } from "src/action";
import { InvestmentRes } from "src/interfaces/Orders";
import ExitSuccessIcon from "src/assets/exit-success.svg";
import { ConfirmCancel } from "../.";

interface CancelOrdersModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  record?: InvestmentRes;
  onSuccess?: () => void;
}

const mappedRoute = {
  deposit: "deposit",
  withdrawal: "withdraw",
};

const CancelOrders = ({
  record,
  visible,
  setVisible,
  onSuccess,
}: CancelOrdersModalProps) => {
  const [isOtpModalVisible, setIsOtpModal] = React.useState<boolean>(false);
  const [isDoneModal, setIsDoneModal] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const recordDetails = record?.details?.toLowerCase();
  const orderType = ["deposit", "withdrawal"].includes(recordDetails)
    ? mappedRoute[recordDetails]
    : "investment";
  const cancelRoute =
    record?.type === "EXIT" || record?.status === "Closing"
      ? "cancelPendingExit"
      : "cancel";

  const formik = useFormik({
    initialValues: {
      otpCode: "",
    },
    validate: async (values) => {
      try {
        await object()
          .shape({
            otpCode: string().when("otpCode", {
              is: true,
              then: string().required("OTP code is required"),
            }),
          })
          .validate(values, { context: { otpCode: isOtpModalVisible } });
        return {};
      } catch (error) {
        return yupToFormErrors(error);
      }
    },
    onSubmit: async (values) => {
      setError("");
      if (!isOtpModalVisible) {
        const res = await cancelOrders(orderType, record?._id, cancelRoute);
        if (res.success) {
          setVisible(false);
          setIsOtpModal(true);
        } else {
          setError(res?.message);
        }
      } else {
        const res = await validateCancelOrders(
          orderType,
          record?._id,
          values,
          cancelRoute,
        );
        if (res.success) {
          setIsOtpModal(false);
          setIsDoneModal(true);
          if (onSuccess) onSuccess();
        } else {
          setError(res?.message);
        }
      }
    },
  });

  const sendForOTP = async () => {
    setError("");
    const res = await cancelOrders(orderType, record?._id, cancelRoute);
    return !res?.success ? setError(res?.message) : null;
  };

  return (
    <>
      <ConfirmCancel
        visible={visible}
        onConfirm={formik.handleSubmit}
        onCancel={() => setVisible(false)}
        buttonLoading={formik.isSubmitting}
      />
      <OTPModal
        isModalVisible={isOtpModalVisible}
        setModalVisible={setIsOtpModal}
        error={error}
        formik={formik}
        onResend={sendForOTP}
      />
      <SuccessModal
        icon={ExitSuccessIcon}
        visible={isDoneModal}
        onDone={() => {
          setIsDoneModal(false);
          window.location.reload();
        }}
        onCancel={() => setIsDoneModal(false)}
        text="Your order has been canceled"
      />
    </>
  );
};

export default CancelOrders;
