import _ from "lodash";
import { IMarketClassifiedSmartfolios } from "src/interfaces/ClassifiedSmartfolios";

export const groupByKey = (values: any, key: string) => {
  const grouped = _.mapValues(_.groupBy(values, key), (clist) =>
    clist.map((value) => value),
  );
  return grouped;
};

export const mapByLayer = (data: any[], tickers: any[]) => {
  const grouppedTickers = groupByKey(tickers, "symbol");

  const updatedData = data.map((d: any) => {
    d.aliases = {};
    for (const [key] of Object.entries(d.securities)) {
      d.aliases[key] = grouppedTickers[key]
        ? grouppedTickers[key][0]
        : undefined;
    }
    return d;
  });
  let test = groupByKey(updatedData as any, "layer");
  (test as any)["All"] = updatedData;

  for (const prop in test) {
    const allProducts = [...test[prop]];
    (test as any)[prop] = groupByKey((test as any)[prop], "theme");
    (test as any)[prop]["All"] = allProducts;
  }
  return test;
};

export const getSmartfolio = (
  smartfolio?: string,
  data?: IMarketClassifiedSmartfolios,
) => {
  if (smartfolio && data)
    return data.All.assetClass.All.smartfolio.find(
      (el) => el.name === smartfolio,
    );
  else return undefined;
};
