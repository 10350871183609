import { message } from "antd";
import { useMutation } from "react-query";
import axiosInstance from "src/utils/axiosInstance";
import { BuyI } from "../utils/interfaces";

const BUY_STOCK_ORDER_ENDPOINT = '/dashboard/direct-orders/buy'

const buyTicker = async (payload: BuyI) => {
    const response = await axiosInstance.post(BUY_STOCK_ORDER_ENDPOINT, payload, { baseURL: 'v2' });
    return response.data;
};

export const useBuyTickerOrder = () => {
    return useMutation(buyTicker, {
        onSuccess: () => {
            message.success("Data saved successfully!")
        },
        onError: (error) => {
            message.error("Something went wrong!")
        },
    });
}