import { message } from "antd";
import { useMutation } from "react-query";
import axiosInstance from "src/utils/axiosInstance";

const CANCEL_ORDER_ENDPOINT = "dashboard/direct-orders/orders";

const cancelOrder = async (order_id: number) => {
    return axiosInstance.put(
        `${CANCEL_ORDER_ENDPOINT}/${order_id}/cancel`,
        null,
        { baseURL: "v2" }
    );
};

export const useCancelOrder = () => {
    return useMutation(cancelOrder, {
        onSuccess: () => {
            message.success("Order caceled successfully!");
        },
        onError: (error) => {
            message.error("Something went wrong!");
        },
    });
};
