import axios from "src/utils/axiosInstance";
import { mapByLayer } from "./utilities";

export const fetchTickers = async () => {
  try {
    const { data } = await axios.get(`/roboAdvisor/tickers`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchThemes = async () => {
  try {
    const { data } = await axios.get("/trackers/?structure=flat");

    // NOTE this is just a hack, before fixing this from the backend!
    const filtered = data.filter((el) => el.id !== "Top VC Booster");

    const tickers = await fetchTickers();
    return mapByLayer(filtered, tickers);
  } catch (error) {
    throw error;
  }
};

export const addInvestment = async (values: any) => {
  try {
    const res = await axios.post(`/investment`, values);
    return { data: res.data, status: res.status, success: true };
  } catch ({ data }) {
    const message = data?.message || "Something went wrong";
    return { message, status: data?.status, success: false };
  }
};

export const validateInvestmentOTP = async (values: any) => {
  try {
    const res = await axios.post("/investment/otp", values);
    return { data: res.data, status: res.status, success: true };
  } catch (err) {
    const error = err.response.data;
    const message = error?.message || "Something went wrong";
    return { message, status: error?.status, success: false };
  }
};
