import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoalData } from "src/store/Goal-Based/goal";
import moment from "moment";
import { UpdateFieldPayload } from "./goal";

const initialState: GoalData = {
  period: 12,
  start_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  end_date: moment().add(5, "month").format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
  contribution_type: 1,
  initial_amount: "0",
  goal_amount: "10000",
  objective: null,
};

const GoalAmountSlice = createSlice({
  name: "goalAmount",
  initialState,
  reducers: {
    updateGoalField: (
      state: any,
      action: PayloadAction<UpdateFieldPayload<GoalData>>,
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { updateGoalField } = GoalAmountSlice.actions;
export default GoalAmountSlice.reducer;
